import React from "react";

function Home() {
  return (
    <div>
      <h3>hello from home</h3>
    </div>
  );
}
export default Home;
