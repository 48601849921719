import {combineReducers} from "redux";

export default function rootReducer(state, action)  {
    switch(action.type) {
        // case '':
        //     return {};
        // case '':
        //     return {};
        default:
            return state;
    }
}